<template>
  <div>
    <v-overlay :value="overlay" absolute style="z-index:999999999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-toolbar flat>
      <v-icon left>mdi-bullhorn-outline</v-icon>
      <v-toolbar-title>CAMPAÑAS DE MARKETING</v-toolbar-title>
      <v-divider class="mx-4" vertical></v-divider>
      <v-btn @click="abrirCampaniaDialog(null)" color="blue" small dark
        ><v-icon>mdi-plus</v-icon> Crear</v-btn
      >
      <v-col col="2">
        <v-text-field class="mt-4" label="buscar" v-model="buscarCampania"></v-text-field>
      </v-col>
      <v-col col="2">
        <v-checkbox class="mt-4" v-model="incluirInactivas" label="Incluir inactivas"></v-checkbox>
      </v-col>
      <v-divider class="mx-4" vertical></v-divider>
      <v-btn :to="`crm_anuncios`" small color="blue" dark>Anuncios</v-btn>
    </v-toolbar>

    <v-data-table
      :search="buscarCampania"
      :headers="headersCampania"
      :items-per-page="itemsPerPage"
      :items="campanias"
      class="elevation-1"
      small
      hide-default-footer
    >
      <template v-slot:item.marketing_campaign_id="{ item }">
        <v-btn
          text
          small
          color="green"
          @click="abrirCampaniaDialog(item.marketing_campaign_id)"
          ><v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn @click="borrarCampanaia(item.marketing_campaign_id)" text small>
          <v-icon color="red" small>mdi-delete</v-icon>
        </v-btn>
      </template>
      <template v-slot:footer>
        <v-pagination
          class="mt-10"
          v-model="currentPage"
          :length="pageCount"
          @input="handlePageChange"
          total-visible="10"
        ></v-pagination>
      </template>
    </v-data-table>

    <!-- dialogo campanias -->
    <v-dialog v-model="dialogCampania" persistent width="700">
      <v-form ref="formCampania">
        <v-card>
          <v-toolbar dark color="blue" height="40" flat>
            <v-icon left>mdi-bullhorn-outline</v-icon>
            Campaña
            <v-spacer></v-spacer>
            <v-btn small text @click="dialogCampania = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-row class="mt-1 mx-2 pt-0 mt-0">
            <v-col md="12" class="py-0 my-0">
              <v-text-field
                v-model="datosCampania.campaign_name"
                label="Nombre"
                :rules="requiredRule"
              ></v-text-field>
            </v-col>

            <v-col v-if="datosCampania.marketing_campaign_id != ''">
              <v-alert color="green" dark class="pa-2"
                >Anuncios

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="blue-grey lighten-2"
                      v-bind="attrs"
                      v-on="on"
                      fab
                      x-small
                      @click="agregarAnuncio"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Agregar anuncio</span>
                </v-tooltip>
              </v-alert>
              <v-data-table
                :headers="headersAnuncios"
                :items-per-page="itemsPerPage"
                :items="anuncios"
                class="elevation-1"
                small
                hide-default-footer
              >
                <template v-slot:item.mk_anuncio_id="{ item }">
                  <v-btn title="editar anuncio" text @click="editarAnuncio(item)">
                    <v-icon color="green" small>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn title="borrar anuncio" text @click="borrarAnuncio(item.mk_anuncio_id)">
                    <v-icon color="red" small>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-toolbar>
            <v-btn color="primary" small @click="guardarCampania">
              <v-icon>mdi-content-save</v-icon>Guardar
            </v-btn>
            <v-divider vertical class="px-2" inset></v-divider>
            <v-btn text small @click="dialogCampania = false">
              <v-icon>mdi-content-cancel</v-icon>Cancelar
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- DIALOGO ANUNCIO-->
    <v-dialog v-model="dialogAnuncio" persistent width="400">
      <v-form ref="formAnuncio">
        <v-card>
          <v-toolbar dark color="blue" height="30" flat>
            Anuncio
            <v-spacer></v-spacer>
            <v-btn small text @click="dialogAnuncio = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-row class="mt-1 mx-2 pt-0 mt-0">
            <v-col md="12" class="py-0 my-0">
              <v-text-field
                v-model="anuncio_nombre"
                label="Nombre del anuncio"
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-1 mx-2 pt-0 mt-0">
            <v-col md="12" class="py-0 my-0">
              <v-textarea
                v-model="anuncio_texto"
                label="Texto del anuncio"
                :rules="requiredRule"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="mt-1 mx-2 pt-0 mt-0">
            <v-col md="12" class="py-0 my-0">
              <v-autocomplete
                v-model="anuncio_tipo_servicio"
                label="Tipo de servicio"
                :rules="requiredRule"
                :items="tiposServicio"
                item-text="nombre"
                item-value="servicio_crm_id"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-toolbar>
            <v-btn color="primary" small @click="guardarAnuncio">
              <v-icon>mdi-content-save</v-icon>Guardar
            </v-btn>
            <v-divider vertical class="px-2" inset></v-divider>
            <v-btn text small @click="dialogAnuncio = false">
              <v-icon>mdi-content-cancel</v-icon>Cancelar
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from 'vue'

export default {
  name: "Campaniamk",
  data: () => ({
    show: false,

    requiredRule: [(v) => !!v || "El campo es requerido"],
    options: {},
    setfilters: [],
    selected: [],
    users: [],
    totalItems: 1,
    currentPage: 1,
    itemsPerPage: 50,
    pageCount: 1,
    overlay: false,
    buscarCampania: "",
    incluirInactivas: false,

    required: [(v) => !!v || "El campo es requerido"],

    headersCampania: [
      { text: "ID", align: "start", value: "marketing_campaign_id" },
      { text: "Nombre", align: "start", value: "campaign_name" },
      { text: "Estado", align: "start", value: "mk_estado" },
      
    ],
    campanias: [],
    dialogCampania: false,
    datosCampania: {
      marketing_campaign_id: "",
      monto: 0,
      status_id: "",
      estado: "",
      from_date: "",
      thru_date: "",
      campaign_name: "",
      campaign_sumary: "",
    },
    anuncios: [],
    crearCampania: false,
    headersAnuncios: [
      { text: "ID", align: "start", value: "mk_anuncio_id" },
      { text: "Nombres", align: "start", value: "descripcion" },
      { text: "Servicio", align: "start", value: "nombre"}
    ],
    dialogAnuncio: false,
    anuncio_id: "",
    anuncio_nombre: "",
    anuncio_texto: "",
    anuncio_tipo_servicio: "",
  }),
  computed: {
    ...mapState("master", ["loadingBtn", "companies"]),
    ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),
    ...mapState("master", ["loadingTable", "tenantId", "user"]),
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setLoadingTable",
      "setTitleToolbar",
      "setTenantId",
    ]),
    ...mapActions("master", ["requestApi", "alertNotification"]),
    ...mapActions("access", []),

    cargarListaCampanias() {
      this.setLoadingTable(true);
      this.overlay = true;
      this.setUrl("campania-marketing");
      this.requestApi({
        method: "GET",
        data: {
          page: this.currentPage,
          page_count: this.pageCount,
          page_size: this.itemsPerPage,
        },
      })
        .then((res) => {
          //console.log(res.data);
          this.campanias = res.data._embedded.campania_marketing;
          this.pageCount = res.data.page_count;
          this.currentPage = res.data.page;
          this.totalItems = res.data.total_items;
          this.overlay = false;
        })
        .then(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    },
    abrirCampaniaDialog(cmId) {
      this.datosCampania = {
        marketing_campaign_id: "",
        campaign_name: "",
      };
      this.dialogCampania = true;
      this.crearCampania = true;
      if (cmId != null) {
        this.crearCampania = false;
        this.cargarDatosCampania(cmId);
      }
    },

    cargarDatosCampania(id) {
      this.setLoadingTable(true);
      this.setUrl("campania-marketing/" + id);
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          //console.log(res.data.detail);
          this.datosCampania.marketing_campaign_id =
            res.data.detail.campania.marketing_campaign_id;
          this.datosCampania.campaign_name =
            res.data.detail.campania.campaign_name;
          this.datosCampania.estado = res.data.detail.campania.op_estado;
          this.datosCampania.from_date = res.data.detail.campania.from_date;
          this.datosCampania.thru_date = res.data.detail.campania.thru_date;
          this.datosCampania.status_id = res.data.detail.campania.status_id;
          this.anuncios = res.data.detail.anuncios;
          this.tiposServicio = res.data.detail.servicios;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    guardarCampania() {
      if (!this.$refs.formCampania.validate()) {
        return false;
      }
      this.setLoadingTable(true);
      this.setUrl("campania-marketing");
      this.requestApi({
        method: "POST",
        data: {
          accion:
            this.crearCampania == true ? "crearCampania" : "modificarCampania",
          datosCampania: this.datosCampania,
        },
      })
        .then((res) => {
          //console.log(res.data.detail);

          this.alertNotification({
            param: {
              html: res.data.detail.msg,
            },
          });
          if (this.crearCampania == true) {
            this.cargarDatosCampania(res.data.detail.cm_id);
          } else {
            this.cargarListaCampanias();
            this.dialogCampania = false;
          }
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    agregarAnuncio() {
      this.dialogAnuncio = true;
      this.anuncio_id = "";
      this.anuncio_nombre="";
      this.anuncio_texto="";
      this.anuncio_tipo_servicio="";
    },

    guardarAnuncio() {
      if (!this.$refs.formAnuncio.validate()) {
        return false;
      }
      this.setLoadingTable(true);
      this.setUrl("campania-marketing");
      this.requestApi({
        method: "POST",
        data: {
          accion: this.anuncio_id == "" ? "crearAnuncioCampania" : "modificarAnuncioCampania",
          datosAnuncio: {
            mk_anuncio_id: this.anuncio_id == "" ? null : this.anuncio_id,
            marketing_campaign_id: this.datosCampania.marketing_campaign_id,
            descripcion: this.anuncio_nombre,
            texto: this.anuncio_texto,
            tipo_servicio_id: this.anuncio_tipo_servicio
            
          },
        },
      })
        .then((res) => {
          //console.log(res.data.detail);

          this.alertNotification({
            param: {
              html: res.data.detail.msg,
            },
          });
          this.cargarDatosCampania(this.datosCampania.marketing_campaign_id);
          this.dialogAnuncio = false;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    borrarCampanaia(mcId) {
      Vue.swal({
            html: "Está seguro de eliminar esta campaña ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cerrar',
            ...this.paramAlertQuestion
        }).then( result => {
            
            if (result.isConfirmed) {
              this.setLoadingTable(true);
              this.setUrl("campania-marketing");
              this.requestApi({
                method: "POST",
                data: {
                  accion: "borrarCampania",
                  marketing_campaign_id: mcId,
                },
              })
                .then((res) => {
                  //console.log(res.data.detail);

                  this.alertNotification({
                    param: {
                      html: res.data.detail.msg,
                    },
                  });
                  this.cargarListaCampanias();
                })
                .then(() => {
                  this.setLoadingTable(false);
                });

              }
            })
    },
    borrarAnuncio(mkAnuncioId) {
      Vue.swal({
                html: "Está seguro de eliminar este anuncio ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then( result => {
                
                if (result.isConfirmed) {
                this.setLoadingTable(true);
                this.setUrl("campania-marketing");
                this.requestApi({
                  method: "POST",
                  data: {
                    accion: "borrarAnuncioCampania",
                    mkAnuncioId: mkAnuncioId,
                  },
                })
                  .then((res) => {
                    //console.log(res.data.detail);

                    this.alertNotification({
                      param: {
                        html: res.data.detail.msg,
                      },
                    });
                    this.cargarDatosCampania(this.datosCampania.marketing_campaign_id);
                  })
                  .then(() => {
                    this.setLoadingTable(false);
                  });
                }
              });
              
    },
    editarAnuncio(item) {
      this.dialogAnuncio = true;      
      this.cargarAnuncio(item.mk_anuncio_id)
    },
    cargarAnuncio(mkAnuncioId) {
      this.setUrl("campania-marketing");
      this.requestApi({
        method: "POST",
        data: {
          accion: "cargarDatosAnuncio",
          mkAnuncioId: mkAnuncioId},
      })
        .then((res) => {
          this.anuncio_id = res.data.detail.anuncio.mk_anuncio_id
          this.anuncio_nombre = res.data.detail.anuncio.descripcion;
          this.anuncio_texto = res.data.detail.anuncio.texto;
          this.anuncio_tipo_servicio = res.data.detail.anuncio.tipo_servicio_id;
          
          
          
        })
        .then(() => {
          this.setLoadingTable(false);
        });

    },

    handlePageChange(value) {
      this.cargarDatos();
    },
  },
  mounted() {
    this.cargarListaCampanias();
    this.setTitleToolbar("CAMPANIAS DE MARKETING");
  },
};
</script>
